import { render, staticRenderFns } from "./InviteConfirmation.vue?vue&type=template&id=4a407789&scoped=true&"
import script from "./InviteConfirmation.vue?vue&type=script&lang=ts&"
export * from "./InviteConfirmation.vue?vue&type=script&lang=ts&"
import style0 from "./InviteConfirmation.less?vue&type=style&index=0&id=4a407789&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a407789",
  null
  
)

export default component.exports