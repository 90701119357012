











































































import InviteConfirmation from '@/popupcomponents/inviteconfirmation/InviteConfirmation';
export default InviteConfirmation;
